import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {catchError, distinctUntilChanged, map, switchMap, tap, throttleTime} from 'rxjs/operators';
import {async} from 'rxjs/internal/scheduler/async';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FormGroup} from '@angular/forms';
import {ApiService} from '../../services/api.service';
import {LogisticDeliveryPointModel} from '../../models/logistic-delivery-point.model';
import {HubModel} from '../../models/hub.model';

@Component({
  selector: 'app-delivery-point-selector',
  templateUrl: './delivery-point-selector.component.html',
  styleUrls: ['./delivery-point-selector.component.scss'],
})
export class DeliveryPointSelectorComponent implements OnInit {

  @Input() parentFormGroup: FormGroup;
  @Input() textareaControlName: string;
  @Input() textareaClass: string;
  @Input() deliveryTypeId: number;

  @Input() textareaId: string;
  @Input() disabled: boolean = false;

  @Input() hubOriginId = HubModel.HUB_MSK;

  public suggestions: Observable<any>;
  public loadingSuggestions: boolean = false;
  addressInput$ = new Subject<string>();

  @Output('onSelect') emitToParent = new EventEmitter<LogisticDeliveryPointModel>();
  @Output('onChange') emitChangeToParent = new EventEmitter<string>();

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.loadAddress();
  }

  private loadAddress() {
    this.suggestions = this.addressInput$.pipe(
      distinctUntilChanged(),
      throttleTime(500, async, {leading: false, trailing: true}),
      tap(() => this.loadingSuggestions = true),
      switchMap(
        term => this.apiService.getDeliveryPointsSuggestions({'address': term, 'delivery_type_id': this.deliveryTypeId})
        .pipe(
          catchError(() => of([])), // empty list on error
          map((suggests) => {
            return suggests.map(deliveryPoint => ({
              ...deliveryPoint,
              delivery_period: this.getDeliveryPeriod(deliveryPoint)
            }));
          }),
          tap((suggests) => {
            this.loadingSuggestions = false
          })
        )
      )
    );
  }

  onAddressInput(event) {
    this.emitChangeToParent.emit(event.target.value);
    this.addressInput$.next(event.target.value);
  }

  onAddressSelect(event: MatAutocompleteSelectedEvent) {
    this.emitToParent.emit(event.option.value);
  }

  /**
   * Как отобразить выбранное значение
   * @param value
   */
  displayFn(value): string {
    if (!value) {
      return null;
    }

    // если объект дадаты с адресом
    if (value.hasOwnProperty('value')) {
      return value.value;
    }

    // если просто строка (при инициализации, например)
    return value;
  }

  getCurrentZone(zones) {
    return zones.find(x => x.hub_origin_id === (+this.hubOriginId));
  }


  getDeliveryPeriod(deliveryPoint) {
    const currentZone = this.getCurrentZone(deliveryPoint.zones);

    if (!currentZone) {
      return null;
    }

    const { delivery_period_min, delivery_period_max, delivery_period_type} = currentZone;

    const deliveryTypeName = this.getDeliveryTypeName(delivery_period_type, delivery_period_max);

    if (delivery_period_min === delivery_period_max) {
      const term = delivery_period_max;
      const pluralizedDay = term === 1 ? 'день' : term < 5 ? 'дня' : 'дней';
      return `${term} ${deliveryTypeName} ${pluralizedDay}`;
    }

    const pluralizedDay = delivery_period_max === 1 ? 'дня' : 'дней';
    return `от ${delivery_period_min} до ${delivery_period_max} ${deliveryTypeName} ${pluralizedDay}`;
  }

  getDeliveryTypeName(deliveryPeriodType, deliveryPeriodMax) {
    if (deliveryPeriodType == 1) {
      return deliveryPeriodMax === 1 ? 'календарный' : 'календарных';
    }

    if (deliveryPeriodType == 2) {
      return deliveryPeriodMax === 1 ? 'рабочий' : 'рабочих';
    }

    return '';
  }
}
